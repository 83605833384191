import React, { useState } from 'react';

const Contact2 = () => {
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [message, setMessage] = useState('');

    const toggleContactInfo = () => {
      setShowContactInfo(!showContactInfo);
    };

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formData.name || !formData.email || !formData.message) {
          setMessage('Please fill out all fields.');
          return;
        }
        try {
          const response = await fetch('https://formspree.io/f/mrgnjlrr', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          });
      
          if (response.ok) {
            setMessage("Thank you for your message!");
            setFormData({ name: '', email: '', message: '' }); // Clear the form
          } else {
            throw new Error('Failed to submit contact info');
          }
        } catch (error) {
          console.error('Error:', error);
          setMessage('Failed to send message.');
        }
    };

    return (
      <div className="bg-black border-l border-r border-white text-white flex flex-col md:flex-row" style={{ height: 'calc(100vh - 115px)' }}>
        <div className="border-r md:w-1/5 p-4">
          <button onClick={toggleContactInfo} className="text-blue-500 font-bold mb-2">
            &gt; Contact Info
          </button>
          {showContactInfo && (
            <div className="mt-2 text-blue-300">
              <p><a href="tel:+14379678878">// +1 (437)-967-8878</a></p>
              <p><a href="mailto:caseyanetor@gmail.com">// caseyanetor@gmail.com</a></p>
            </div>
          )}
        </div>
        <div className="flex-1 p-4 overflow-auto flex items-center justify-center">
          <div className='max-w-lg w-full'>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-md font-bold mb-2" htmlFor="name">Name</label>
                <input name="name" value={formData.name} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-black" id="name" type="text" placeholder="Your name" />
              </div>
              <div className="mb-4">
                <label className="block text-md font-bold mb-2" htmlFor="email">Email</label>
                <input name="email" value={formData.email} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-black" id="email" type="email" placeholder="Your email" />
              </div>
              <div className="mb-4">
                <label className="block text-md font-bold mb-2" htmlFor="message">Message</label>
                <textarea name="message" value={formData.message} onChange={handleInputChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-black" id="message" placeholder="Your message" rows="4"></textarea>
              </div>
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">
                Send Message
              </button>
              {message && <p className="text-red-500">{message}</p>}
            </form>
          </div>
        </div>
      </div>
    );
};

export default Contact2;
