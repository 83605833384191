import React, { useState, useEffect } from 'react';

const SnakeGame = () => {
  const [snake, setSnake] = useState([{ x: 2, y: 2 }]);
  const [food, setFood] = useState({ x: 5, y: 5 });
  const [direction, setDirection] = useState('RIGHT');
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);

  const playfieldWidth = 23.9; // Assuming each cell is 10px, for 240px wide playfield
  const playfieldHeight = 41; // Assuming each cell is 10px, for 400px high playfield

  useEffect(() => {
    if (!gameStarted || gameOver) return;

    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp': if (direction !== 'DOWN') setDirection('UP'); break;
        case 'ArrowDown': if (direction !== 'UP') setDirection('DOWN'); break;
        case 'ArrowLeft': if (direction !== 'RIGHT') setDirection('LEFT'); break;
        case 'ArrowRight': if (direction !== 'LEFT') setDirection('RIGHT'); break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    const moveSnake = () => {
      let newHead = { ...snake[0] };
      switch (direction) {
        case 'UP': newHead.y -= 1; break;
        case 'DOWN': newHead.y += 1; break;
        case 'LEFT': newHead.x -= 1; break;
        case 'RIGHT': newHead.x += 1; break;
      }

      // Check for collision with walls or self
      if (newHead.x < 0 || newHead.x >= playfieldWidth || newHead.y < 0 || newHead.y >= playfieldHeight || snake.some(segment => segment.x === newHead.x && segment.y === newHead.y)) {
        setGameOver(true);
        return;
      }

      // Check if snake eats the food
      if (newHead.x === food.x && newHead.y === food.y) {
        setScore(score + 1);
        // Ensure the new food position is not on the snake
        let newFoodX, newFoodY;
        do {
          newFoodX = Math.floor(Math.random() * playfieldWidth);
          newFoodY = Math.floor(Math.random() * playfieldHeight);
        } while (snake.some(segment => segment.x === newFoodX && segment.y === newFoodY));
        
        setFood({ x: newFoodX, y: newFoodY });
        setSnake(prev => [newHead, ...prev]);
      } else {
        setSnake(prev => [newHead, ...prev.slice(0, -1)]);
      }
    };

    const gameLoop = setInterval(moveSnake, 300);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      clearInterval(gameLoop);
    };
  }, [snake, direction, food, gameOver, gameStarted, score]);

  const toggleGame = () => {
    if (gameStarted && !gameOver) {
      setGameStarted(false);
      setGameOver(true);
    } else {
      setGameStarted(true);
      setGameOver(false);
      setSnake([{ x: 2, y: 2 }]);
      setFood({ x: Math.floor(Math.random() * playfieldWidth), y: Math.floor(Math.random() * playfieldHeight) });
      setDirection('RIGHT');
      setScore(0);
    }
  };

  return (
    <div className="flex flex-row items-center justify-between px-5 rounded-md w-[490px] h-[460px] bg-green-500 border-2 border-white">
      <div className="relative w-[239px] rounded-md h-[410px] bg-gray-500">
        {snake.map((segment, index) => (
          <div key={index} className="absolute bg-green-600" style={{ width: '10px', height: '10px', left: `${segment.x * 10}px`, top: `${segment.y * 10}px` }} />
        ))}
        <div className="absolute bg-red-600" style={{ width: '10px', height: '10px', left: `${food.x * 10}px`, top: `${food.y * 10}px` }} />
      </div>
      <div className="flex flex-1 flex-col justify-between h-[405px] w-1/2 ml-4">
        <div className="bg-blue-500 p-2 rounded-md">
          <p className="text-white pb-5">Instructions</p>
          <p className="text-white pb-1">// Use Keyboard arrows to play.</p>
          <p className="text-white pb-1">Score: <span id="score">{score}</span></p>
        </div>
       
       <div className='flex flex-row justify-between items-center'>
       <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-2 rounded-lg ml-0 mr-0" onClick={toggleGame}>
          {gameStarted && !gameOver ? 'Stop Game' : 'Start Game'}
        </button>
        {gameOver && <p className="text-red-500 text-md">Game Over</p>}
       </div>
       
      </div>
    </div>
  );
};

export default SnakeGame;
