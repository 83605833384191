import React, { useEffect, useRef, useState } from 'react';
import { useProject } from '../ProjectContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../slide-in.css'; // Ensure the CSS is imported

const ProjectDetailsPanel = () => {
    const { selectedProject, clearSelectedProject } = useProject();
    const [isClosing, setIsClosing] = useState(false);
    const panelRef = useRef(null);
    const images = {
        murder: require('../assets/murder.png'),
        chore: require('../assets/chore.png'),
        slate: require('../assets/slate.png'),
        bang: require('../assets/bang.png'),
        haunted: require('../assets/haunted.png'),
        memejoy: require('../assets/memejoy.png'),
        shareden: require('../assets/shareden.png'),
        gpt3: require('../assets/gpt3.png'),
        library: require('../assets/library.png'),

        // Add more keys and require statements as needed
      };

    useEffect(() => { 
        const handleClickOutside = (event) => {
            if (panelRef.current && !panelRef.current.contains(event.target)) {
                startClosing();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectedProject]);

    const startClosing = () => {
        setIsClosing(true);
        setTimeout(() => {
            clearSelectedProject();
            setIsClosing(false);
        }, 500); // this should match the duration of your slide-out animation
    };

    if (!selectedProject && !isClosing) return null;

    const panelClasses = `panel ${isClosing ? 'slide-out' : 'slide-in'}`;

    return (
        <div className={panelClasses} ref={panelRef}>
            <div className='bar'>
                <h2 className="text-xl font-bold">{selectedProject.title}</h2>
                <button onClick={startClosing} className="">
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <div className="pt-10 pl-6 overflow-auto text-white">
                <img src={images[selectedProject.imageKey]} alt={selectedProject.title} />
                <p className="my-4">{selectedProject.introduction}</p>
                <p className="my-4">{selectedProject.overview}</p>
                <p>{selectedProject.plan}</p>
                <ul className="list-disc pl-5 my-4">
                    {selectedProject.features.map(features => (
                        <li key={features}>{features}</li>
                    ))}
                </ul>
                <p>{selectedProject.CandS}</p>
                <p>{selectedProject.impact}</p>
                <ul className="list-disc pl-5 my-4">
                    {selectedProject.technologies.map(tech => (
                        <li key={tech}>{tech}</li>
                    ))}
                </ul>
                <p className='my-4'>{selectedProject.information}</p>
                <a href={selectedProject.liveUrl} className="text-blue-500 hover:text-blue-700">
                    Visit Project
                </a>
            </div>
        </div>
    );
};

export default ProjectDetailsPanel;
