import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faBehance, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';  // Import the PDF icon


const Footer = () => {
  return (
    <footer className="bg-black border border-white text-white flex justify-between items-center p-4 h-[45px]">
      <div className="flex items-center space-x-4">
        <span className="pl-2 pr-1">Find me in:</span>
        <a href="https://www.linkedin.com/in/caseyae/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="lg" className='pr-3 hover:text-blue-500'/> {/* LinkedIn Icon */}
        </a>
        <a href="https://www.behance.net/caseyanetor1" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faBehance} size="lg" className='pr-3 hover:text-blue-500'/>  {/* Behance Icon */}
        </a>
        <a href={`${process.env.PUBLIC_URL}/Casey_AE.pdf`} download="Casey_AE.pdf">
        <FontAwesomeIcon icon={faFilePdf} size="lg" className='hover:text-blue-500'/>  {/* pdf Icon */}
        </a>
      </div>
      <div className="flex items-center">
        <a href="https://github.com/caseyae11" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} size="lg" className='pr-3 hover:text-blue-500'/>   {/* GitHub Icon */}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
