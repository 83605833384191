import React from 'react';
import { Link } from 'react-router-dom';

const AboutMe = () => {
  return (
    <div className="bg-black border-l border-r border-white text-white flex flex-col md:flex-row">
      {/* Menu Dropdown Section */}
      <div className="border-r p-4 md:w-1/5">
        <h2 className="text-lg font-semibold text-blue-500">About Me</h2>
      </div>

      {/* About Me Information Section */}
      <div className="flex-1 p-4 overflow-auto">
        <p className="text-gray-300 pt-2">
          Hello! I'm <strong>Casey Anetor</strong>. Rooted in a family where technology and business were daily bread, I've nurtured a deep-seated passion for both. My tech journey began scribbling business ideas in a childhood notebook, dreaming of innovations that make life simpler—like an app for outsourcing chores!
        </p>
        <h3 className="text-lg text-blue-500 mt-6 mb-4">Education & Professional Journey</h3>
        <p className="text-gray-300">
          With a BSc in Information Science from Pan Atlantic University and a Postgraduate certificate in Interactive Media Management from Sheridan College, I've blended learning with practical application. From crafting engaging designs at the Sheridan College Library to optimizing web interfaces at the Bank of Industry Nigeria, each role has been a step toward mastering the art of technology.
        </p>
        <h3 className="text-lg text-blue-500 mt-6 mb-4">Technical Toolkit</h3>
        <ul className="text-blue-300 list-disc pl-5">
          <li>Proficient in: HTML/CSS, JavaScript, TypeScript, Python, PHP, SQL</li>
          <li>Frameworks: React.js, Node.js, Django</li>
          <li>Tools: Visual Studio Code, Git, MongoDB, Adobe Creative Suite</li>
        </ul>
        <h3 className="text-lg text-blue-500 mt-6 mb-4">Interests & Inspirations</h3>
        <p className="text-gray-300">
          Outside my professional life, I love sports. I'm an avid cook and soccer enthusiast, drawing creativity from the kitchen and resilience from the field. Sometimes you can find me at the gym, or taking walks listening to music <strong>ps: I've got the best playlists.</strong> Inspired by family, I have a strong passion in business, tech and media. This passion drives me to innovate constantly and try to excel in everything I undertake.
        </p>
        <h3 className="text-lg text-blue-500 mt-6 mb-4">Looking Forward</h3>
        <p className="text-gray-300">
          As I look to the future, my goal is to further my expertise in Full-Stack Software Engineering and Data Science, paving the way for groundbreaking solutions in tech. I am eager to transform complex challenges into innovative, user-friendly solutions.
        </p>
        <p className="text-gray-300 mt-4">
          Thank you for visiting my portfolio! You can find my resume in the pdf button below. Feel free to <Link to="/contact" className="text-blue-500 hover:text-blue-700">contact me</Link> if you’d like to collaborate or just chat about tech and innovation.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
