import React, { useState, useEffect } from 'react';
import './CursorStyles.css'; // Ensure this CSS file path is correct

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updateCursor = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', updateCursor);
    document.body.style.cursor = 'none'; // Hide the default cursor

    return () => {
      document.removeEventListener('mousemove', updateCursor);
      document.body.style.cursor = ''; // Restore default cursor
    };
  }, []);

  return (
    <>
      <div
        className="cursor-halo"
        style={{ left: `${position.x}px`, top: `${position.y}px` }}
      />
      <div
        className="custom-cursor"
        style={{ left: `${position.x}px`, top: `${position.y}px` }}
      />
    </>
  );
};

export default CustomCursor;
