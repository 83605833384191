import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Projects2 from './components/Projects2';
import Footer from './components/Footer';
import AboutMe from './components/AboutMe';
import ProjectDetailsPanel from './components/ProjectDetailsPanel'; // Import the details panel
import CustomCursor from './CustomCursor';
import Contact2 from './components/Contact2';
import { ProjectProvider } from './ProjectContext';

const App = () => {
  return (
    <ProjectProvider>  {/* Wrap everything inside the ProjectProvider */}
      <Router>
        <CustomCursor />
        <Navbar />
        <ProjectDetailsPanel />  
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/projects" element={<Projects2 />} />
          <Route path="/about" element={<AboutMe />} />
          <Route path="/contact" element={<Contact2 />} />
        </Routes>
        <Footer />
      </Router>
    </ProjectProvider>
  );
};

export default App;
