import React, { useState, useEffect } from 'react';
import SnakeGame from './SnakeGame';


const Home = () => {
  const titles = ["Web Designer", "Front End Developer", "UI/UX Designer", "Back End Developer"];
  const [titleIndex, setTitleIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    const delay = reverse ? 75 : subIndex === titles[titleIndex].length ? 490 : 150;
    const timeout = setTimeout(() => {
      if (subIndex === titles[titleIndex].length + 1 && !reverse) {
        // Start deleting after the full text is shown
        setReverse(true);
        setSubIndex(subIndex - 1);
      } else if (subIndex === 0 && reverse) {
        // Move to the next title after the text is completely deleted
        setReverse(false);
        setTitleIndex((titleIndex + 1) % titles.length);
      } else {
        // Increment or decrement the subIndex based on reverse
        setSubIndex(subIndex + (reverse ? -1 : 1));
      }
    }, delay);

    return () => clearTimeout(timeout);
  }, [subIndex, titleIndex, reverse, titles]);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearInterval(blinkInterval);
  }, []);

  return (
    <div 
      style={{ height: 'calc(100vh - 115px)' }}
      className="home bg-black border-l border-r border-white text-white flex flex-col sm:flex-row justify-between items-center p-5">
        <div className="flex flex-col justify-center w-full sm:w-1/2 md:w-full lg:w-1/2 pl-16 pr-6 custom-pl-small">
          <h1 className="text-[18px] sm:text-4xl">Hello. I am</h1>
          <h2 className="text-[62px] sm:text-6xl leading-tight">Casey Anetor</h2>
          <h3 className="text-[20px] sm:text-3xl text-blue-700 pt-2 pb-4">&gt; {`${titles[titleIndex].substring(0, subIndex)}${blink ? "|" : " "}`}</h3>
          <p className="text-blue-300 text-sm sm:text-base">// Welcome to my portfolio.</p>
          <p className="text-blue-300 text-sm sm:text-base">// You can call me Casey or Ace.</p>
          <p className="text-blue-300 text-sm sm:text-base">// Play the game if you're on a larger screen.</p>
          <p className="text-blue-300 pb-3 text-sm sm:text-base">// PS: My resume is the pdf button down there. Have Fun!</p>
          <p className="text-blue-700 text-sm sm:text-base">
            <span>const </span>
            <span className="text-green-300">githublink = </span>
            <a href="https://github.com/caseyae11" className="text-red-300">https://github.com/caseyae11</a>
          </p>
        </div>
        <div className="hidden lg:block w-full lg:w-1/2">
          <SnakeGame />
        </div>
    </div>
  );
  
};

export default Home;
