import React, { createContext, useContext, useState } from 'react';

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
    const [selectedProject, setSelectedProject] = useState(null);

    const clearSelectedProject = () => setSelectedProject(null);

    return (
        <ProjectContext.Provider value={{ selectedProject, setSelectedProject, clearSelectedProject }}>
            {children}
        </ProjectContext.Provider>
    );
};

export const useProject = () => useContext(ProjectContext);
