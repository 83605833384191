import React, { useState } from 'react';
import projectsData from '../projectsData.json';  // Adjust the path as necessary
import { useProject } from '../ProjectContext'; // Make sure to import the hook from the context setup

const Projects2 = () => {
  const [selectedTech, setSelectedTech] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { setSelectedProject } = useProject(); // Use the context to set selected project
  const technologies = ['React', 'HTML', 'CSS', 'Design', 'Javascript', 'PHP', 'SQL', 'MongoDB', 'Node', 'UI/UX'];


  // Handle checkbox changes to filter projects
  const handleCheckboxChange = (tech) => {
    setSelectedTech(prev =>
      prev.includes(tech)
        ? prev.filter(item => item !== tech)
        : [...prev, tech]
    );
  };

  // Filter projects based on selected technologies
  const filteredProjects = selectedTech.length > 0
    ? projectsData.filter(project => project.technologies.some(tech => selectedTech.includes(tech)))
    : projectsData;

  return (
    <div className="bg-black border-l border-r border-white text-white flex flex-col md:flex-row ">
      {/* Dropdown for Filter Section on Smaller Screens */}
      <div className="md:hidden">
        <button 
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="text-white bg-black p-4 w-full text-left"
        >
          Filter Projects
        </button>
        <div className={`${isDropdownOpen ? 'block' : 'hidden'} p-4`}>
          <ul>
            {technologies.map((tech) => (
              <li key={tech}>
                <label className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={selectedTech.includes(tech)}
                    onChange={() => handleCheckboxChange(tech)}
                    className="accent-blue-500"
                  />
                  <span>{tech}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Permanent Filter Section for Larger Screens */}
      <div className="hidden md:block md:flex md:flex-col md:w-56 lg:w-72 xl:w-88 p-4 border-r border-white" style={{ height: 'calc(100vh - 115px)' }}>
        <h2 className="text-lg font-semibold">Projects</h2>
        <ul className="mt-4">
          {technologies.map((tech) => (
            <li key={tech} className="my-2">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={selectedTech.includes(tech)}
                  onChange={() => handleCheckboxChange(tech)}
                  className="accent-blue-500"
                />
                <span>{tech}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>

      {/* Projects Display Section (common for all screen sizes) */}
      <div className="flex-1 p-4 overflow-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredProjects.map(project => (
            <div key={project._id} className="border border-white p-4 rounded-lg bg-black relative group" onClick={() => setSelectedProject(project)}>
              <div className="absolute inset-0 border-2 border-black p-6 rounded-lg flex items-center justify-center bg-white opacity-0 group-hover:opacity-100 transition-opacity duration-500 ">
                <span className="text-black text-center px-4">Click for preview</span>
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold">{project.title}</h3>
                <p className="text-sm my-2">{project.description}</p>
                {/* Optionally include a live project link here if needed */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects2;
