import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const closeMenu = () => {
        setIsOpen(false);
    };
    
    return (
        <nav className="bg-black text-white p-5 flex justify-between items-center border border-white relative" style={{ zIndex: 150 }}>
            <Link to="/" className="text-lg font-bold" onClick={closeMenu}>casey_ace</Link>
            <button onClick={() => setIsOpen(!isOpen)} className="md:hidden z-20">
                <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />
            </button>
            <div className={`${isOpen ? 'flex' : 'hidden'} flex-col absolute bg-black inset-x-0 top-full h-screen p-5 border-t border-white md:hidden transition-all ease-out duration-1000`}>
                <ul>
                    <li className="py-2">
                        <Link to="/projects" className="hover:text-gray-300 block" onClick={() => setIsOpen(false)}>_projects</Link>
                    </li>
                    <li className="py-2">
                        <Link to="/about" className="hover:text-gray-300 block" onClick={() => setIsOpen(false)}>_about me</Link>
                    </li>
                    <li className="py-2">
                        <Link to="/contact" className="hover:text-gray-300 block" onClick={() => setIsOpen(false)}>_contact me</Link>
                    </li>
                </ul>
            </div>
            <div className={`${isOpen ? 'hidden' : 'block'} hidden md:flex flex-grow items-center justify-end space-x-4`}>
                <Link to="/projects" className="hover:text-gray-300">projects</Link>
                <Link to="/about" className="hover:text-gray-300">about me</Link>
                <Link to="/contact" className="hover:text-gray-300">contact me</Link>
            </div>
        </nav>
    );
};

export default Navbar;
